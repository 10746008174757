import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { X } from "react-feather";
import useSrlHelper from "../../hooks/useSrlHelper";

const AssetBatteryFilter = (props) => {
    const { column: { filterValue, setFilter } } = props;
    const [selectValue, setSelectValue] = useState("0");
    // const [lessMore, setLessMore] = useState(false);
    const [localValue, setLocalValue] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [disableValue, setDisableValue] = useState(false);
    const { getFilterTypeOptions } = useSrlHelper();

    const filterTypeOptions = getFilterTypeOptions();

    useEffect(() => {
        setSelectValue((filterValue && filterValue.length >= 3) ? filterValue[1] : "0");
        setLocalValue((filterValue && filterValue.length > 3) ? filterValue.substring(3) : "");
    }, [filterValue]);

    useEffect(() => {
        //if (!localValue || !selectValue) return;

        // var v = localValue;
        // if (v.length > 0) {
        //     setFilter("{" + selectValue.toString() + "}" + v);
        // }
        // else {
        //     setFilter("");
        // }

        var filterTypeOption = filterTypeOptions.find((v, i) => v.value === selectValue);
        if (filterTypeOption) {
            setPlaceholder(filterTypeOption.placeholder);
            setDisableValue(filterTypeOption.disableValue);
        }

        if (selectValue !== "0")
        {
            var v = localValue;
            setFilter("¤" + selectValue.toString() + "¤" + v);
        }

    }, [localValue, selectValue]);

    const handleSelectChange = (e) => {
        console.log("e", e);
        setSelectValue(e.target.value);
        // var filterTypeOption = filterTypeOptions.find((v, i) => v.value === e.target.value);
        // if (filterTypeOption) {
        //     setPlaceholder(filterTypeOption.placeholder);
        //     setDisableValue(filterTypeOption.disableValue);
        // }
        setLocalValue("");
        e.preventDefault();
    };

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="p-0 m-0 flex-grow-1">
                <Form.Group className="flex-grow-1">
                    <Form.Select className="" name="filterType" id="filterType" value={selectValue} onChange={handleSelectChange}>
                        {filterTypeOptions.map((v, i) => {
                            return <option value={v.value}>{v.text}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            </div>
            <div class="p-0 m-0 ">
                <Form.Group className="p-0 m-0">
                    <Form.Control
                        type="text"
                        placeholder={placeholder}
                        value={localValue}
                        disabled={disableValue}
                        onChange={(e) => { setLocalValue(e.target.value); }}
                    />


                </Form.Group>

            </div>
            <div class="flex-shrink-1">
                <Button variant="" className="p-0 m-0" onClick={() => { setFilter(""); }}><X /></Button>
            </div>
            {/* <span style={{ cursor: "pointer" }} onClick={() => { setLessMore(!lessMore); }} >{!lessMore ? "less than" : "more than"}</span> */}
        </div>
    );
};

export default AssetBatteryFilter;
